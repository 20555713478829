import type { GetServerSideProps } from 'next';
import React from 'react';
import { Hero } from '../components/home/hero';
import { LatestNews } from '../components/home/latest-news';
import { MainSponsors } from '../components/home/main-sponsors';
import { NextMatches } from '../components/home/next-matches';
import { Partners } from '../components/home/partners';
import { PastMatches } from '../components/home/past-matches';
import { Tables } from '../components/home/tables';
import { TeamInfos } from '../components/home/team-infos';
import { Layout } from '../components/layout/layout';
import { Schema } from '../components/layout/schema';
import { getNextMatches, getPastMatches } from '../db/match';
import { getNewsInfos } from '../db/news';
import { getMainSponsors } from '../db/sponsor';
import { getTableEntries } from '../db/table';
import { getTeamInfos } from '../db/team';
import { cache } from '../lib/cache';
import type { Match } from '../types/match';
import type { NewsInfo } from '../types/news-info';
import type { Sponsor } from '../types/sponsor';
import type { TableEntry } from '../types/table-entry';
import type { TeamInfo } from '../types/team-info';

interface PageProps {
  tableEntries: TableEntry[];
  pastMatches: Match[];
  nextMatches: Match[];
  newsInfos: NewsInfo[];
  teamInfos: TeamInfo[];
  sponsors: Sponsor[];
}

export default function HomePage(props: PageProps): React.JSX.Element {
  return (
    <Layout
      title="SV Gmundner Milch"
      description="Seit 1921 unverzichtbarer Bestandteil der Traunseestadt"
      image={'a429e6ca-60f6-4db8-8933-1fd594b8395b.jpg'}>
      <Schema />
      <Hero />
      <PastMatches matches={props.pastMatches} />
      <LatestNews newsInfos={props.newsInfos} />
      <NextMatches matches={props.nextMatches} />
      <Partners />
      <Tables tableEntries={props.tableEntries} />
      <TeamInfos teamInfos={props.teamInfos} />
      <MainSponsors sponsors={props.sponsors} />
    </Layout>
  );
}

export const getServerSideProps: GetServerSideProps = cache<PageProps>(async () => {
  return {
    props: {
      pastMatches: await getPastMatches(),
      nextMatches: await getNextMatches(),
      tableEntries: await getTableEntries(),
      newsInfos: (await getNewsInfos()).slice(0, 6),
      teamInfos: await getTeamInfos(),
      sponsors: await getMainSponsors(),
    },
  };
});
