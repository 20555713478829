import Link from 'next/link';
import React from 'react';
import type { Sponsor } from '../../types/sponsor';
import { Container } from '../container';
import { Item } from '../sponsor/item';
import { ArrowAnimatedIcon } from './arrow-animated-icon';

export const MainSponsors = ({ sponsors }: { sponsors: Sponsor[] }): React.JSX.Element => (
  <section className="bg-blue-600 py-24">
    <Container>
      <div className="mb-8 flex items-center justify-between text-white">
        <h2 className="text-3xl font-bold sm:text-4xl">Sponsoren</h2>
        <Link href="/verein/sponsoren" className="group inline-flex items-center hover:underline">
          Alle Sponsoren <ArrowAnimatedIcon />
        </Link>
      </div>
      <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 md:gap-6">
        {sponsors.map(sponsor => (
          <Item key={sponsor.name} sponsor={sponsor} />
        ))}
      </div>
    </Container>
  </section>
);
