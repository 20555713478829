import React from 'react';
import type { Match } from '../../types/match';
import { Container } from '../container';
import { Pattern } from '../pattern';
import { Club } from './club';

export const NextMatches = ({ matches }: { matches: Match[] }): React.JSX.Element => (
  <section className="relative bg-blue-600 py-24" id="naechste-spiele">
    <Pattern className="absolute inset-0 h-full w-full text-black" />
    <Container>
      <h2 className="mb-8 text-3xl font-bold text-white sm:text-4xl">Nächste Spiele</h2>
      {matches.length === 0 ? (
        <div className="text-white">Keine Termine</div>
      ) : (
        <div className="grid gap-3 sm:grid-cols-2 md:gap-6">
          {matches.map((match, i) => (
            <div
              key={i}
              className="flex flex-wrap items-stretch justify-center rounded-sm bg-white px-2 py-3 shadow sm:py-5">
              <div className="flex shrink-0 items-center justify-center p-4 py-3">
                <Club name={match.homeName} logo={match.homeLogo} />
                <div className="w-8 text-center text-lg font-bold">VS</div>
                <Club name={match.guestName} logo={match.guestLogo} />
              </div>
              <div className="flex w-56 grow flex-col justify-center space-y-2 truncate p-4 py-3 text-center">
                <div className="truncate text-sm font-medium uppercase tracking-wide text-gray-500">{match.type}</div>
                <div className="text-xl font-bold">
                  {new Date(match.date).toLocaleString('de-AT', {
                    hour: '2-digit',
                    minute: '2-digit',
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    timeZone: 'Europe/Vienna',
                  })}
                </div>
                <div className="truncate">{match.location}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </Container>
  </section>
);
