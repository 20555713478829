import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import type { Sponsor } from '../../types/sponsor';

export const Item = ({ sponsor }: { sponsor: Sponsor }): React.JSX.Element => (
  <Link
    href={sponsor.link}
    className="group relative block rounded-sm bg-white p-3 shadow sm:p-6"
    target="_blank"
    rel="nofollow">
    <div className="relative transition-transform duration-500 group-hover:scale-105">
      <div className="pt-[66.66%]" />
      <Image
        src={process.env.NEXT_PUBLIC_STORAGE + sponsor.logo}
        title={sponsor.name}
        alt={sponsor.name}
        className="object-contain"
        fill={true}
        sizes="(min-width: 1024px) 210px, (min-width: 768px): 25vw, (min-width: 640px) 33vw, 50vw"
      />
    </div>
    {sponsor.campus && (
      <div className="absolute bottom-2 right-2 h-8 w-8">
        <Image
          src={process.env.NEXT_PUBLIC_STORAGE + '4f06588c-6d34-4358-95ee-a971a87ca43b.svg'}
          width={32}
          height={32}
          className="h-full w-full object-cover"
          title="Campus Partner"
          alt="Campus Partner"
        />
      </div>
    )}
  </Link>
);
