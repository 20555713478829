import { StarIcon } from '@heroicons/react/20/solid';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import type { NewsInfo } from '../../types/news-info';
import { ArrowAnimatedIcon } from '../home/arrow-animated-icon';
import { Logo } from '../logo';
import { Pattern } from '../pattern';

export const Item = ({ newsInfo }: { newsInfo: NewsInfo }): React.JSX.Element => {
  return (
    <Link href={'/news/' + newsInfo.slug} className="group relative block h-[25rem] max-w-md sm:max-w-none">
      <div className="relative h-[23rem] overflow-hidden rounded-sm bg-blue-500 shadow">
        {newsInfo.image ? (
          <Image
            src={process.env.NEXT_PUBLIC_STORAGE + newsInfo.image}
            alt=""
            fill={true}
            className="object-cover transition-transform duration-500 group-hover:scale-105"
            sizes="(min-width: 1024px) 352px, (min-width: 640px) 50vw, 100vw"
          />
        ) : (
          <>
            <Pattern className="absolute inset-0 h-full w-full text-black" />
            <Logo className="relative mx-auto h-[14.5rem] py-8" />
          </>
        )}
      </div>
      <div className="absolute bottom-0 left-0 right-4 h-[10.5rem] rounded-sm rounded-tl-none bg-white p-6 shadow sm:p-6">
        <div className="flex">
          <div className="inline-flex h-6 items-center rounded-sm bg-gray-200 px-2 text-sm">
            {new Date(newsInfo.date).toLocaleString('de-AT', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
              timeZone: 'Europe/Vienna',
            })}
          </div>
          {newsInfo.pinned && (
            <div title="Hervorgehoben">
              <StarIcon className="ml-1 h-6 w-6 rounded-sm bg-blue-600 p-1.5 text-white" />
            </div>
          )}
        </div>
        <div className="my-2 line-clamp-2 h-14 overflow-hidden hyphens-auto break-words pt-1.5 font-medium">
          {newsInfo.title}
        </div>
        <span className="inline-flex h-6 items-center text-blue-500 group-hover:underline">
          Mehr lesen <ArrowAnimatedIcon />
        </span>
      </div>
    </Link>
  );
};
