import Script from 'next/script';
import React from 'react';

const schema = {
  '@context': 'https://schema.org',
  '@type': 'SportsOrganization',
  name: 'SV Gmundner Milch',
  sport: 'Football',
  url: process.env.NEXT_PUBLIC_HOST + '/',
  logo: process.env.NEXT_PUBLIC_HOST + '/assets/favicons/icon-base.svg',
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'Gmunden',
    postalCode: '4810',
    streetAddress: 'Karl Plentzner Stasse 9a',
    addressCountry: 'AT',
  },
  email: 'office@svg1921.at',
  telephone: '+4376127340020',
  faxNumber: '+436764143409',
};

export const Schema = (): React.JSX.Element => (
  <Script id="schema" type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />
);
